.footer {
  background: radial-gradient(ellipse at bottom, #21233a00 30%, #171941 80%);
}

:root {
  color-scheme: dark;
}

.cs {
  text-align: center;
  font-family: sans-serif;

}


@media screen and (min-width: 992px) {
  .dropdown-menu .dropdown-item {
    color: #d2d2d2;
  }
  .dropdown-menu {
    background: linear-gradient(to bottom, #171941 0%, #21233a 100%);
    border: 1px dashed #454bc5;
    border-radius: 10px;
  }
  .dropdown-menu:before {
    color: #454bc5;
    top: -6px;
  }

  .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .bootstrap-select .dropdown-menu.inner li a:hover, .bootstrap-select .dropdown-menu.inner li a:focus {
    outline: 1px solid #454bc5;
    background-color: transparent;

  }
}

.bg-default {
  background-color: #253a67 !important;
}

.navbar-dark {
  background: linear-gradient(to top, rgba(23, 25, 65, 0) 0%, #171941 15%, #101431 100%);
}

@media screen and (max-width: 991px) {
  .navbar .navbar-collapse {
    max-height: calc(100vh - 1.4rem);
    overflow: auto;
    overflow-x: hidden;
  }
}

.card-pricing.card-plain.card-price:hover {
  background: rgba(35, 38, 87, 0.5);
  box-shadow: 13px 13px 77px -24px #101128;
}

.space-nav {
  .dropdown-item {
    svg:nth-child(1) {
      display: inline-block;
    }

    svg:nth-child(2) {
      display: none;
    }
  }

  .dropdown-item:hover {
    svg:nth-child(2) {
      display: inline-block;
    }

    svg:nth-child(1) {
      display: none;
    }
  }
}

@keyframes Floatingy {
  from {
    -webkit-transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(50px, 0);
  }
  to {
    -webkit-transform: translate(0, 0px);
  }
}

@keyframes Floatingx {
  from {
    -webkit-transform: translate(50px, 0px);
  }
  65% {
    -webkit-transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(50px, 0px);
  }
}

.presentation-cards {
  border: 2px solid #171941;
  border-radius: 200px;
  position: absolute;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-name: Floatingy;
  -moz-animation-name: Floatingy;
  float: left;

  &.pcard-1 {
    left: 65%;
    top: 23%;
    height: 175px;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
  }

  &.pcard-2 {
    left: 50%;
    top: 80%;
    height: 100px;
    -webkit-animation-duration: 6s;
    -moz-animation-duration: 6s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
  }

  &.pcard-3 {
    left: -3%;
    top: 42%;
    height: 150px;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 18s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
  }

  &.pcard-4 {
    left: 50%;
    top: -11%;
    height: 125px;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
  }

  &.pcard-5 {
    left: 3%;
    top: -3%;
    height: 130px;
    -webkit-animation-duration: 8s;
    -moz-animation-duration: 8s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;

  }
}


// min tablet

.hover_in_place {
  animation: hover_in_place 20s infinite ease-in-out;
}

.hover_in_place:hover {
  animation: hover_in_place 20s infinite ease-in-out, disappear 1s forwards;
}

.disappear:hover {
  animation: disappear 1s forwards;
}

@keyframes disappear {
  100% {
    opacity: 0;
  }
}


@keyframes hover_in_place {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(50px);
  }
  75% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }

}



/* all text sorts*/
/* First set of elements with blue background */
h1, h4, li, th, select {

  &::selection {
    background: rgba(0, 91, 190, 0.75) !important;
  }
}

/* Second set of elements with green background */
h2, h5, span, input {

  &::selection {
    background: rgba(140, 255, 0, 0.75) !important;
  }
}

/* Third set of elements with red background */
h3, h6, b, td, label, textarea {

  &::selection {
    background: rgba(255, 21, 0, 0.75) !important;
  }
}

/* Fourth set of elements with yellow background */
ul, ol, strong {

  &::selection {
    background: rgba(255, 255, 0, 0.75) !important;
  }
}

/* Fifth set of elements with orange background */
blockquote, code, p {

  &::selection {
    background: rgba(255, 165, 0, 0.75) !important;
  }
}

/* Sixth set of elements with purple background */
em, cite, pre, a {
  &::selection {
    background: rgba(128, 0, 102, 0.75) !important;
  }
}

body:hover {
  animation: mouseGoesCrazy 1s infinite ease-in-out;
  animation-delay: 20s;
}

@keyframes mouseGoesCrazy {
  0% {
    cursor: unset;
  }
  10% {
    cursor: zoom-in;
  }
  20% {
    cursor: zoom-out;
  }
  30% {
    cursor: wait;
  }
  40% {
    cursor: progress;
  }
  50% {
    cursor: cell;
  }
  60% {
    cursor: crosshair;
  }
  70% {
    cursor: text;
  }
  80% {
    cursor: alias;
  }
  90% {
    cursor: copy;
  }
  100% {
    cursor: none;
  }


}
#canvas {
  cursor: pointer !important;
}
